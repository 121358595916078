import { Popover } from '@headlessui/react';
import React, { Fragment } from 'react';
import { StructuredText } from 'react-datocms';
import tw from 'twin.macro';
import DatoLink from '@datocms/Link';
import globalRules from '@datocms/render-rules';
import type { LinkBlock } from '@datocms/types';
import Heroicon from 'components/Heroicon';
import Transition from 'components/Transition';
import { focusStyle } from './common';

const DescribedLink = ({
	name,
	description,
	heroicon,
	...props
}: LinkBlock) => {
	return (
		<DatoLink
			{...props}
			tw="-m-3 p-3 flex items-start rounded-lg hover:bg-gray-50"
			css={focusStyle}>
			<div tw="flex-shrink-0 h-6 w-6 ">
				<Heroicon
					heroicon={heroicon ?? ''}
					tw="h-6 w-6 text-primary"
					aria-hidden="true"
				/>
			</div>
			<div tw="ml-4">
				<p tw="text-base font-medium text-gray-900">{name}</p>
				{description && (
					<div tw="mt-1 text-sm text-gray-500">
						<StructuredText data={description} customRules={globalRules} />
					</div>
				)}
			</div>
		</DatoLink>
	);
};

const Cta = ({ name, heroicon, ...props }: LinkBlock) => {
	return (
		<div key={name} tw="flow-root">
			<DatoLink
				{...props}
				css={focusStyle}
				tw="-m-3 p-3 flex items-center rounded-md text-base font-medium text-gray-900 hover:bg-gray-100">
				<Heroicon
					heroicon={heroicon ?? ''}
					tw="flex-shrink-0 h-6 w-6 text-gray-400"
					aria-hidden="true"
				/>
				<span tw="ml-3">{name}</span>
			</DatoLink>
		</div>
	);
};

const NavbarPopoverRecord = ({ name, links, ctas }) => {
	return (
		<Popover tw="relative">
			{({ open }) => {
				return (
					<>
						<Popover.Button
							className="group"
							css={[
								open ? tw`text-gray-900` : tw`text-gray-500`,
								tw`rounded-md inline-flex items-center text-base font-medium hover:text-gray-900`,
								focusStyle,
							]}>
							<span>{name}</span>
							<Heroicon
								heroicon="chevron-down"
								css={[
									open ? tw`text-gray-600` : tw`text-gray-400`,
									tw`ml-2 h-5 w-5 group-hover:text-gray-500`,
								]}
								aria-hidden="true"
							/>
						</Popover.Button>

						<Transition
							show={open}
							as={Fragment}
							enter={tw`transition ease-out duration-200`}
							enterFrom={tw`opacity-0 translate-y-1`}
							enterTo={tw`opacity-100 translate-y-0`}
							leave={tw`transition ease-in duration-150`}
							leaveFrom={tw`opacity-100 translate-y-0`}
							leaveTo={tw`opacity-0 translate-y-1`}>
							<Popover.Panel
								static
								tw="absolute -ml-4 mt-3 px-2 w-screen max-w-md  sm:px-0 transform lg:(ml-0 left-1/2 -translate-x-1/2)">
								<div tw="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden">
									<div tw="relative bg-white grid gap-6 px-5 py-6 sm:(gap-8 p-8)">
										{links.map((item) => (
											<DescribedLink key={item.id} {...item} />
										))}
									</div>
									{ctas.length !== 0 && (
										<div tw="bg-gray-50 px-5 py-5 space-y-6 sm:(flex space-y-0 space-x-10 px-8)">
											{ctas.map((item) => (
												<Cta key={item.id} {...item} />
											))}
										</div>
									)}
								</div>
							</Popover.Panel>
						</Transition>
					</>
				);
			}}
		</Popover>
	);
};

export default NavbarPopoverRecord;
