import type { LinkBlock } from '@datocms/types';
import type { SmartLinkProps } from 'components/SmartLink';
import Link from 'components/SmartLink';

type DatoLinkInput = Omit<
	LinkBlock,
	'heroicon' | 'description' | 'name' | 'id'
>;
type DatoLinkOutput = Pick<SmartLinkProps, 'href' | 'tabMode'>;

const getSmartlinkProps = ({
	fragment,
	page,
	openInNewTab,
	linkMode,
	href,
}: DatoLinkInput): DatoLinkOutput => {
	const tabMode = openInNewTab ? 'new_tab' : 'same_tab';

	let effectiveHref = '/';
	if (linkMode === 'page' && page) {
		const appendfragment = fragment ?? '';

		if (page.__typename === 'HomePageRecord') {
			effectiveHref = `/${appendfragment}`;
		}

		if (page.__typename === 'ComposablePageRecord') {
			effectiveHref = `/${page.slug}${appendfragment}`;
		}

		if (page.__typename === 'JournalArticleRecord') {
			effectiveHref = `/journal/${page.slug}${appendfragment}`;
		}

		if (page.__typename === 'JournalIndexRecord') {
			effectiveHref = `/journal/${appendfragment}`;
		}
	}
	if (linkMode === 'href' && href) {
		effectiveHref = href;
	}

	return {
		href: effectiveHref,
		tabMode,
	};
};

export type DatoLinkProps = Omit<SmartLinkProps, keyof DatoLinkOutput> &
	DatoLinkInput;

const DatoLink: React.FC<DatoLinkProps> = ({
	fragment,
	page,
	openInNewTab,
	linkMode,
	href,
	...props
}) => {
	const datoSmartLinkProps = getSmartlinkProps({
		fragment,
		page,
		openInNewTab,
		linkMode,
		href,
	});
	return <Link {...datoSmartLinkProps} {...props} />;
};

export default DatoLink;
