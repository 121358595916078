import type { CSP } from 'lib/HeadStrictDynamic';

const vercelUrl = process.env.VERCEL_URL;
const siteUrl = process.env.NEXT_PUBLIC_SITE_URL;
const origin = siteUrl || (vercelUrl && `https://${vercelUrl}`);

const fontSrc = ['font-src', `'self'`];
const styleSrc = ['style-src', `'unsafe-inline'`];
const imgSrc = [
	'img-src',
	`'self'`,
	'data:',
	...(origin ? [origin] : []),
	'https://www.datocms-assets.com',
	'https://i.ytimg.com',
	'https://flagcdn.com',
];

const connectSrc = [
	'connect-src',
	`'self'`,
	...(origin ? [origin] : []),
	'https://graphql-listen.datocms.com',
	'https://www.datocms-assets.com',
	'https://o1107586.ingest.sentry.io',
	'https://sentry.io',
	'https://vitals.vercel-insights.com',
	'https://site-api.datocms.com',
];

const scriptSrc = (scriptHashes?: string[], nonce?: string) => [
	'script-src',
	...(nonce ? [`'nonce-${nonce}'`] : []),
	...(scriptHashes ? scriptHashes : []),
	...(nonce || scriptHashes ? [`'strict-dynamic'`] : ['self']),
	`'unsafe-inline'`,
	`https: http:`,
];
export const prodCsp = (scriptHashes?: string[], nonce?: string): CSP => [
	fontSrc,
	styleSrc,
	imgSrc,
	connectSrc,
	scriptSrc(scriptHashes, nonce),
	['object-src', `'none'`],
	['base-uri', `'none'`],
];

const codespacesHttps = 'wss://*.githubpreview.dev';
const codespacesWss = 'wss://*.githubpreview.dev';

export const devCsp: CSP = [
	fontSrc,
	[...fontSrc, codespacesHttps],
	styleSrc,
	[...imgSrc, codespacesHttps],
	[...connectSrc, codespacesHttps, codespacesWss],
	[...scriptSrc(), `'unsafe-eval'`],
];

export const excludeFromCspHeaderInjection = (pageName: string) => {
	// only include the following pages (just home atm)
	const includeNamePatterns = [/^\/$/];
	return (
		process.env.NODE_ENV === 'development' ||
		process.env.NO_CSP_HEADER_INJECTION ||
		!pageName ||
		!includeNamePatterns.some((regex) => regex.test(pageName))
	);
};
