export const selectFocusable = (
	el: Element | null | undefined
): HTMLElement | null | undefined => {
	return el?.querySelector(
		`button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])`
	);
};
export const isElementVisible = (el: Element | null | undefined) => {
	if (!el) return false;
	const rect = el.getBoundingClientRect();
	if (!rect.height && !rect.width) {
		return false;
	}
	if (getComputedStyle(el).visibility === 'hidden') {
		return false;
	}
	return true;
};

export const selectAllVisible = (
	selector: string,
	querySelectorAll?: (selector: string) => NodeListOf<Element>
) => {
	const elements =
		querySelectorAll?.(selector) || document.querySelectorAll(selector);
	return [...elements].filter(isElementVisible);
};
