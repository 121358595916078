import { useRouter } from 'next/router';
import { useEffect } from 'react';
import { selectFocusable, selectAllVisible } from 'utils/browser';

const hashChangedHandler = (url: string) => {
	const [, fragment] = url.split('#');
	selectFocusable(document.getElementById(fragment))?.focus();
};
const routeChangedHandler = (url: string) => {
	const [, fragment] = url.split('#');
	const header = document.getElementById('header-navigation');
	const focusable =
		// shift focus to the first focusable element in the fragment section
		(fragment && selectFocusable(document.getElementById(fragment))) ||
		// or shift focus to the first call-to-action in main
		selectFocusable(
			document.getElementById('main-content')?.querySelector('.cta')
		) ||
		// or shift focus to the first focusable within h1 of main (titles with self-link)
		selectFocusable(
			document.getElementById('main-content')?.querySelector('h1')
		) ||
		// or shift focus to the first visible navigation link
		selectFocusable(
			selectAllVisible('.nav-focus-container', header?.querySelectorAll)[0]
		);
	focusable?.focus();
	header?.scrollIntoView();
};

const useShiftFocusOnNavigation = () => {
	const { events } = useRouter();
	useEffect(() => {
		events.on('hashChangeComplete', hashChangedHandler);
		events.on('routeChangeComplete', routeChangedHandler);
		return () => {
			events.off('hashChangeComplete', hashChangedHandler);
			events.off('routeChangeComplete', routeChangedHandler);
		};
	}, []);
};

export default useShiftFocusOnNavigation;
