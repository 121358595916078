import { Global } from '@emotion/react';
import React from 'react';
import tw, { css, theme, GlobalStyles as BaseStyles } from 'twin.macro';
import { purpleHazeTextGradient } from 'styles';

const customStyles = css`
	html {
		scroll-behavior: smooth;
	}
	body {
		-webkit-tap-highlight-color: ${theme`colors.secondary`};
		${tw`antialiased`}
		mark {
			${tw`text-gray-700 font-medium bg-brand-200/80`}
		}
		a > mark {
			${tw`font-bold`}
			${css(purpleHazeTextGradient)}
		}
	}
`;

const GlobalStyles = () => (
	<>
		<BaseStyles />
		<Global styles={customStyles} />
	</>
);

export default GlobalStyles;
