/* This example requires Tailwind CSS v2.0+ */
import 'twin.macro';
import { atom } from 'jotai';
import { useUpdateAtom } from 'jotai/utils';
import Heroicon from 'components/Heroicon';
import { mobileMenuButtonStyle } from './common';

export const searchBoxOffsetAtom = atom<number | null>(null);

const SearchButton = () => {
	const setSearchBoxOffset = useUpdateAtom(searchBoxOffsetAtom);
	return (
		<button
			type="button"
			css={[mobileMenuButtonStyle]}
			onClick={() => {
				const footerSearch = document?.querySelector<HTMLDivElement>(
					'#datocms-site-search-footer'
				);
				footerSearch?.querySelector('input')?.focus();
				footerSearch?.scrollIntoView();
				if (footerSearch) {
					setSearchBoxOffset(footerSearch.offsetTop);
				}
			}}>
			<span tw="sr-only">Search for content on our website</span>
			<Heroicon
				heroicon="search"
				tw="h-6 w-6 sm:(h-8 w-8)"
				aria-hidden="true"
			/>
		</button>
	);
};

export default SearchButton;
