import { Popover } from '@headlessui/react';
import { atom } from 'jotai';
import { useAtomValue, useUpdateAtom } from 'jotai/utils';
import { useCallback } from 'react';
import { useEffect } from 'react';
import tw, { styled } from 'twin.macro';
import DatoLink from '@datocms/Link';
import type { LinkBlock } from '@datocms/types';
import { windowScrollYAtom } from 'atoms/scroll';
import Heroicon from 'components/Heroicon';
import Link from 'components/SmartLink';
import Transition from 'components/Transition';
import { outerContainer } from 'styles';
import { selectFocusable } from 'utils/browser';
import { focusStyle, LogoWithText, mobileMenuButtonStyle } from './common';
import Feedback from './Feedback';
import Locale from './Locale';
import MobileNavigationMenu from './MobileNavigationMenu';
import NavbarPopoverRecord from './NavbarPopoverRecord';
import Search, { searchBoxOffsetAtom } from './Search';

const LinkRecord = ({ name, ...props }: LinkBlock) => {
	return (
		<DatoLink
			{...props}
			css={focusStyle}
			tw="text-base rounded-md font-medium text-gray-500 hover:text-gray-900">
			{name}
		</DatoLink>
	);
};

const scrollAtom = windowScrollYAtom({
	upTolerance: 200,
	downTolerance: 500,
	passiveOnly: true,
	wait: 50,
	maxWait: 100,
	before: true,
});

const showFixedAtom = atom((get) => {
	const { direction, y } = get(scrollAtom);
	const searchBoxOffset = get(searchBoxOffsetAtom);
	if (!searchBoxOffset) {
		return direction === 'up';
	}
	return direction === 'up' && y < searchBoxOffset;
});

const reachedTopAtom = atom((get) => get(scrollAtom).y < 500);

const DesktopNavigationMenu = ({ bar, availableLocales }) => {
	return (
		<>
			<Popover.Group
				className="nav-focus-container"
				as="nav"
				tw="hidden lg:(flex space-x-10)">
				{bar.map((item) => {
					if (item.__typename === 'NavbarPopoverRecord') {
						return <NavbarPopoverRecord key={item.id} {...item} />;
					}
					if (item.__typename === 'LinkRecord') {
						return <LinkRecord key={item.id} {...item} />;
					}
					return <></>;
				})}
			</Popover.Group>
			<div tw="hidden lg:(flex items-center justify-end flex-1 w-0)">
				<Search />
				<Feedback />
				<Locale availableLocales={availableLocales} />
			</div>
		</>
	);
};

const Container = styled.div(outerContainer, tw`px-4 sm:px-6`);

export type NavigationMenuProps = {
	desktopNavigationMenu: any;
	mobileNavigationMenu: any;
	availableLocales: string[];
};

const NavigationMenu = ({
	desktopNavigationMenu,
	mobileNavigationMenu,
	availableLocales,
	...headerProps
}: NavigationMenuProps & React.ComponentProps<'div'>) => {
	return (
		<Popover {...headerProps}>
			{({ open }) => {
				return (
					<>
						<Container>
							<div tw="flex justify-between items-center py-5 lg:justify-start lg:space-x-10">
								<div tw="flex justify-start lg:w-0 lg:flex-1">
									<Link href="/" tw="outline-none">
										<LogoWithText />
									</Link>
								</div>
								<div className="nav-focus-container" tw="-mr-2 -my-2 lg:hidden">
									<div tw="flex flex-row-reverse justify-between">
										<Popover.Button css={mobileMenuButtonStyle}>
											<span tw="sr-only">Open menu</span>
											<Heroicon
												heroicon="menu"
												tw="h-6 w-6 sm:(h-8 w-8)"
												aria-hidden="true"
											/>
										</Popover.Button>
										<Locale availableLocales={availableLocales} />
										<Feedback />
										<Search />
									</div>
								</div>
								<DesktopNavigationMenu
									{...desktopNavigationMenu}
									availableLocales={availableLocales}
								/>
							</div>
						</Container>
						<MobileNavigationMenu
							open={open}
							{...mobileNavigationMenu}
							availableLocales={availableLocales}
						/>
					</>
				);
			}}
		</Popover>
	);
};

const handleScrollTop = (showFixed) => () => {
	if (showFixed) {
		const header = document.querySelector('#header-navigation');
		const main = document.querySelector('#main-content');
		selectFocusable(main)?.focus();
		header?.scrollIntoView();
	}
};

const ScrollTopButton = () => {
	const showFixed = useAtomValue(showFixedAtom);
	const handler = useCallback(handleScrollTop(showFixed), [showFixed]);
	return (
		<button
			type="button"
			tabIndex={-1}
			onClick={handler}
			tw="inline-flex bg-gray-50/80 backdrop-blur-sm text-primary ring-1 ring-offset-0 ring-primary/80 items-center p-3 border border-transparent rounded-full focus:(outline-none ring-2 ring-offset-2 ring-brand-500) hover:(text-white bg-primary/80)">
			<span tw="sr-only">Scroll to the top of the page</span>
			<Heroicon heroicon={'arrow-up'} tw="h-6 w-6" aria-hidden="true" />
		</button>
	);
};

const WithBackdropFixedNav = ({
	desktopNavigationMenu,
	mobileNavigationMenu,
	availableLocales,
	...headerProps
}: NavigationMenuProps & React.ComponentProps<'header'>) => {
	const showFixed = useAtomValue(showFixedAtom);
	const reachedTop = useAtomValue(reachedTopAtom);
	const resetSearchBoxOffset = useUpdateAtom(searchBoxOffsetAtom);
	const menuProps = {
		desktopNavigationMenu,
		mobileNavigationMenu,
		availableLocales,
	};
	useEffect(() => {
		if (reachedTop) {
			resetSearchBoxOffset(null);
		}
	}, [resetSearchBoxOffset, reachedTop]);
	return (
		<header tw="z-50 relative" {...headerProps}>
			<NavigationMenu
				tw="bg-white shadow"
				css={[!reachedTop && tw`invisible`]}
				{...menuProps}
			/>
			<Transition unmount={false} show={!reachedTop && showFixed}>
				<Transition.Child
					tw="fixed top-0 w-full"
					unmount={false}
					enter={tw`ease-out duration-300 `}
					enterFrom={tw`-translate-y-full`}
					enterTo={tw`translate-y-0`}
					leave={tw`ease-in duration-150`}
					leaveFrom={tw`translate-y-0`}
					leaveTo={tw`-translate-y-full`}>
					<NavigationMenu
						tw="bg-gray-50/80 shadow backdrop-blur-sm"
						css={reachedTop && tw`hidden`}
						{...menuProps}
					/>
				</Transition.Child>
				<Transition.Child
					tw="fixed z-50 bottom-0 right-0 py-3 px-3 sm:px-6 lg:px-8"
					unmount={false}
					enter={tw`ease-out duration-300 `}
					enterFrom={tw`translate-y-full`}
					enterTo={tw`translate-y-0`}
					leave={tw`ease-in duration-150`}
					leaveFrom={tw`translate-y-0`}
					leaveTo={tw`translate-y-full`}>
					{!reachedTop && <ScrollTopButton />}
				</Transition.Child>
			</Transition>
		</header>
	);
};

export default WithBackdropFixedNav;
