/* eslint-disable @typescript-eslint/ban-ts-comment */
import type { AppProps } from 'next/app';
import Head from 'next/head';
import { useRouter } from 'next/router';
import Script from 'next/script';
import { useEffect } from 'react';
import Layout from '@layouts/index';
import usePageContext from 'hooks/usePageContext';
import usePathWithLocale from 'hooks/usePathWithLocale';
import useShiftFocusOnNavigation from 'hooks/useShiftFocusOnNavigation';
import { excludeFromCspHeaderInjection } from 'lib/csp';
import GlobalStyles from 'styles/GlobalStyles';

const useRemoveCSPMetaTag = () => {
	const { route } = useRouter();
	useEffect(() => {
		if (!excludeFromCspHeaderInjection(route)) {
			const meta = document.querySelector(
				'meta[http-equiv="Content-Security-Policy"]'
			);
			if (meta) {
				meta.parentNode?.removeChild(meta);
			}
		}
	}, []);
};
function MyApp({ Component, pageProps }: AppProps) {
	useRemoveCSPMetaTag();
	useShiftFocusOnNavigation();
	const path = usePathWithLocale();
	const canonicalUrl = `${process.env.NEXT_PUBLIC_SITE_URL}${
		path !== '/' ? path : ''
	}`;

	const data = pageProps?.querySubscriptionData?.initialData;
	const { availableLocales, layout, isPreview } = usePageContext(data);
	const scripts = (
		<>
			<Script
				id="sentry-script"
				strategy="beforeInteractive"
				async
				src="https://browser.sentry-cdn.com/6.16.1/bundle.min.js"
				integrity="sha384-WkFzsrcXKeJ3KlWNXojDiim8rplIj1RPsCbuv7dsLECoXY8C6Cx158CMgl+O+QKW"
				// @ts-ignore
				crossorigin="anonymous"
			/>
			<Script
				id="before-interactive-inline-test"
				strategy="beforeInteractive"
				// @ts-ignore
				src={null}>
				{`console.log('inline test beforeInteractive')`}
			</Script>
		</>
	);
	if (!isPreview) {
		return (
			<>
				{scripts}
				<Head>
					<link rel="canonical" href={canonicalUrl} />
				</Head>
				<GlobalStyles />
				{layout ? (
					<Layout availableLocales={availableLocales} layout={layout}>
						<Component {...pageProps} />
					</Layout>
				) : (
					<Component {...pageProps} />
				)}
			</>
		);
	}
	return (
		<>
			{scripts}
			<Head>
				<link rel="canonical" href={canonicalUrl} />
				<GlobalStyles />
			</Head>
			<Component {...pageProps} />
		</>
	);
}

export default MyApp;
