/* This example requires Tailwind CSS v2.0+ */
import { Popover } from '@headlessui/react';
import { useRouter } from 'next/router';
import { Fragment } from 'react';
import tw from 'twin.macro';
import Heroicon from 'components/Heroicon';
import SmartLink from 'components/SmartLink';
import Transition from 'components/Transition';
import { defaultRegions } from 'i18n';
import { languageNameTranslations } from 'i18n';
import { mobileMenuButtonStyle } from './common';

type LocaleSwitcherProps = {
	availableLocales: string[];
};

export default function LocaleSwitcher({
	availableLocales,
}: LocaleSwitcherProps) {
	const { locale: currentLocale } = useRouter();

	return (
		<Popover as="div" tw="relative">
			{({ open }) => (
				<>
					<Popover.Button css={[mobileMenuButtonStyle]}>
						<span tw="sr-only">Open language selection</span>
						<Heroicon tw="h-6 w-6 sm:(h-8 w-8)" heroicon="translate" />
					</Popover.Button>
					<Transition
						show={open}
						as={Fragment}
						enter={tw`transition ease-out duration-100`}
						enterFrom={tw`transform opacity-0 scale-95`}
						enterTo={tw`transform opacity-100 scale-100`}
						leave={tw`transition ease-in duration-75`}
						leaveFrom={tw`transition ease-in duration-75`}
						leaveTo={tw`transform opacity-0 scale-95`}>
						<Popover.Panel
							css={[
								tw`origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none`,
							]}>
							{availableLocales.map(([locale, slug]) => {
								return (
									<SmartLink
										key={locale}
										locale={locale}
										href={slug ? `/${slug}` : '/'}
										css={[
											locale === currentLocale && tw`font-black`,
											tw`hover:bg-gray-100`,
											tw`px-4 py-2 block text-sm text-gray-700 rounded-md`,
										]}>
										<div tw="flex justify-between">
											<div>{languageNameTranslations[locale]}</div>
											<img
												alt={`${defaultRegions[locale]}`}
												tw="h-auto w-6"
												src={`https://flagcdn.com/${defaultRegions[
													locale
												].toLowerCase()}.svg`}
											/>
										</div>
									</SmartLink>
								);
							})}
						</Popover.Panel>
					</Transition>
				</>
			)}
		</Popover>
	);
}
