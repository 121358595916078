import tw, { css } from 'twin.macro';

export const gradientTextBase = tw`text-transparent bg-clip-text`;

export const purpleHazeTextGradient = css(
	gradientTextBase,
	tw`bg-gradient-r-purpleHaze`
);
export const invertedLimeTextGradient = css(
	gradientTextBase,
	tw`bg-gradient-r-lime-invert`
);

export const sectionSupertitle = tw`block text-base font-black tracking-wide uppercase`;
export const sectionTitle = tw`block text-3xl leading-8 font-extrabold tracking-tight sm:text-4xl`;
